module.exports = {
  WEBSITE_URL: 'https://trezarfundme.com', // https://pivxstarter.blockcurators.com // https://trezarfundme.com
  BACKEND_URL: 'https://trezarfundme.com:50001',
  APP_NAME: 'Trezarfundme', // PIVXStarter // Trezarfundme
  CURRENCY_NAME: 'TZC', // PIVX // TZC
  CURRENCY: 'TZC', // PIV // TZC
  CURRENCY_SYMBOL: 'TZC', // Ᵽ // TZC
  COLOR_LOGO: require('./tzc-color.png'), // './pivxColorLogo.png' // './tzc-color.png'
  MONOCHROME_LOGO: require('./trezarfundme.png'), // './pivx-starter-logo.png' // './trezarfundme.png'
};

