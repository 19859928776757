import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Divider, Paper, IconButton, InputBase } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import SearchIcon from '@material-ui/icons/SearchOutlined';

import ProjectCard from '../ProjectCard/ProjectCard';
import NavBar from '../NavBar/NavBar';

import CONFIG from '../../config';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '80%',
    },
  },
  headerSpace: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(7),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
  categoryMenuSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  searchContainer: {
    borderTopLeftRadius: '32px',
    borderTopRightRadius: '32px',
    borderBottomLeftRadius: '32px',
    borderBottomRightRadius: '32px',
    display: 'flex',
    justifyContent: 'left',
    maxWidth: 900,
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    '&:hover': {
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    },
  },
}));

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignData: [],
      searchValue: '',
    };

    this.RenderLandingPage = this.RenderLandingPage.bind(this);
  }

  RenderLandingPage() {
    const classes = useStyles();

    const { history, campaignData, selectProjectCallback, statisticsData, userData, onLogout } = this.props;
    const { searchValue } = this.state;

    const ProjectCards = campaignData
      .filter((campaign) => campaign.campaignTitle.toLowerCase().indexOf(searchValue) !== -1 || campaign.campaignSummary.toLowerCase().indexOf(searchValue) !== -1)
      .map((campaign, index) => {
        return (
          <Grid key={`campaign-${index}`} item onClick={(e) => {
            selectProjectCallback(index);
            history.push({
              pathname: '/viewproject',
              search: `?projectId=${campaign.campaignDbId}`,
            });
            e.stopPropagation();
          }}>
            <ProjectCard
              campaignTitle={campaign.campaignTitle}
              campaignSummary={campaign.campaignSummary}
              campaignCategory={campaign.campaignCategory}
              campaignFundingPercentage={campaign.campaignFundingPercentage}
              campaignGoal={campaign.campaignGoal}
              campaignRaised={campaign.campaignRaised}
              totalBackers={campaign.totalBackers}
              campaignCreator={campaign.campaignCreator}
              campaignCreationDate={campaign.campaignCreationDate}
              campaignPictureOrVideo={campaign.campaignPictureOrVideo}
              campaignStatus={campaign.campaignStatus}
              campaignDbId={campaign.campaignDbId}
              campaignType={campaign.campaignType}
            />
          </Grid>)
      });


    return (
      <div className={classes.root}>
        <CssBaseline />
        <NavBar statisticsData={statisticsData} userData={userData} onLogout={onLogout} {...this.props} />
        <main className={classes.content}>
          <div className={classes.headerSpace}>
            <Hidden smDown>
              <Typography variant="h2">{CONFIG.APP_NAME}</Typography>
              <Typography variant="h4">Supporting {CONFIG.CURRENCY_NAME} Community Projects</Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography variant="h4">Supporting {CONFIG.CURRENCY_NAME}  Projects</Typography>
            </Hidden>
          </div>
          <Divider variant="middle" />
          <div className={classes.categoryMenuSpacing}>
            <Paper className={classes.searchContainer}>
              <IconButton className={classes.iconButton}>
                <SearchIcon style={{ fontSize: '2rem' }} />
              </IconButton>
              <InputBase
                className={classes.input}
                placeholder="Search for a Campaign..."
                value={searchValue}
                onChange={(event) => this.setState({ searchValue: event.target.value.toLowerCase() })}
              />
            </Paper>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                {ProjectCards}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }

  render() {
    return (
      <this.RenderLandingPage />
    );
  }
}

export default LandingPage;
