import { createMuiTheme } from '@material-ui/core/styles';
require('typeface-montserrat');

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#c6f24a', //8e64c8 // c6f24a
      main: '#92bf00', // 5c4c7b // 92bf00
      dark: '#5f8f00', //2d0d68 // 5f8f00
      contrastText: '#000000', //ffffff // 000000
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", "sans-serif"',
  },
});

export default theme;
