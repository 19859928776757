import React from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Avatar, Tab, Tabs, TextField, Chip } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FaceIcon from '@material-ui/icons/FaceOutlined';

import ProjectCard from '../ProjectCard/ProjectCard';
import NavBar from '../NavBar/NavBar';

import CONFIG from '../../config';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    padding: theme.spacing(3),
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: '0px !important',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  profileContent: {
    padding: theme.spacing(3),
    minHeight: 500,
  },
  sectionSpacer: {
    marginBottom: theme.spacing(2),
  },
  avatarContainer: {
    marginBottom: theme.spacing(4),
  },
  avatar: {
    width: 120,
    height: 120,
    fontSize: '4em',
    backgroundColor: theme.palette.primary.main,
  },
  centerText: {
    textAlign: 'center',
  },
  updateUserInfoTextarea: {
    width: '325px',
    minWidth: '325px',
    borderRadius: '6px',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", "sans-serif"',
  },
  smallDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sectionHeader: {
    marginTop: theme.spacing(1),
  },
  topCard: {
    backgroundColor: '#1c1c1c',
    color: 'white',
    paddingTop: theme.spacing(1),
    borderRadius: '4px',
  },
  subHeaderSpacer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUserInfoText: this.props.userData.userInfo.user_info || '',
      userInfoText: this.props.userData.userInfo.user_info || '',
      profileValue: 0,
    };

    this.RenderProfilePage = this.RenderProfilePage.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);
  }

  async updateUserInfo() {
    const { userData } = this.props;
    const { updateUserInfoText } = this.state;
    this.setState({ userInfoText: updateUserInfoText });
    try {
      const userInfoUpdateResult = await axios.post(`${CONFIG.BACKEND_URL}/user/updateInfo`, {
        userInfo: updateUserInfoText,
      }, {
        headers: {
          token: userData.token,
        }
      });
      const userInfoUpdateResultData = userInfoUpdateResult.data;
      console.log(userInfoUpdateResultData);
    } catch (e) {
      console.log(e);
    }
  }

  RenderProfilePage() {
    const classes = useStyles();

    const { history, campaignData, selectProjectCallback, userData, statisticsData, onLogout } = this.props;

    const myProjectCards = campaignData
      .filter(((campaign) => campaign.campaignCreator === userData.userInfo.login))
      .map((campaign, index) => {
        return (
          <Grid key={`campaign-${index}`} item onClick={(e) => {
            selectProjectCallback(index);
            history.push({
              pathname: '/viewproject',
              search: `?projectId=${campaign.campaignDbId}`,
            });
            e.stopPropagation();
          }}>
            <ProjectCard
              campaignTitle={campaign.campaignTitle}
              campaignSummary={campaign.campaignSummary}
              campaignCategory={campaign.campaignCategory}
              campaignFundingPercentage={campaign.campaignFundingPercentage}
              campaignGoal={campaign.campaignGoal}
              campaignRaised={campaign.campaignRaised}
              totalBackers={campaign.totalBackers}
              campaignCreator={campaign.campaignCreator}
              campaignCreationDate={campaign.campaignCreationDate}
              campaignPictureOrVideo={campaign.campaignPictureOrVideo}
              campaignStatus={campaign.campaignStatus}
              campaignDbId={campaign.campaignDbId}
              campaignType={campaign.campaignType}
            />
          </Grid>)
      });

    const { updateUserInfoText, userInfoText, profileValue } = this.state;

    const profileContent = () => {
      switch (profileValue) {
        case 0:
          return (
            <>
              <Typography variant="h4" classesName={classes.sectionHeader}>
                Your User Information
              </Typography>
              <Divider className={classes.sectionSpacer} />
              <Typography variant="h5" className={classes.subHeaderSpacer}>
                User Description
              </Typography>
              <TextareaAutosize
                className={classes.updateUserInfoTextarea}
                rows={5}
                value={updateUserInfoText}
                onChange={(e) => this.setState({ updateUserInfoText: e.target.value })}
                placeholder="Write something meaningful about yourself"
              />
              <div style={{ marginTop: '0px' }} />
              <Button color="primary" variant="contained" className={classes.postCommentButton} onClick={() => this.updateUserInfo()}>
                Update Description
              </Button>
              <Typography variant="h5" className={classes.subHeaderSpacer}>
                User {CONFIG.CURRENCY} Address
              </Typography>
              <TextField label={`${CONFIG.CURRENCY} Address`} variant="outlined" defaultValue="" />
              <div style={{ marginTop: '4px' }} />
              <Button color="primary" variant="contained" className={classes.postCommentButton} onClick={() => this.updateUserInfo()}>
                Update Address
              </Button>
              <Typography variant="h5" className={classes.subHeaderSpacer}>
                Update Profile Picture
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
              >
                Upload
              </Button>
            </>
          );
        case 1:
          return (
            <>
              <Typography variant="h4" className={classes.sectionHeader}>
                Your Campaigns
              </Typography>
              <Divider className={classes.sectionSpacer} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="left" spacing={2}>
                    {myProjectCards}
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        case 2:
          return (
            <Chip
              icon={<FaceIcon />}
              label="Not Verified"
              onDelete={() => {}}
              color="secondary"
              variant="outlined"
            />
          );
        default:
          return (<></>);
      }
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <NavBar statisticsData={statisticsData} userData={userData} onLogout={onLogout} {...this.props} />
        <div className={classes.content}>
          <Paper elevation={2} className={classes.paper}>
            <div className={classes.topCard}>
              <Container>
                <Grid container justify="center" alignItems="flex-start">
                  <Avatar className={classes.avatar}>{userData.userInfo.login.substring(0, 2)}</Avatar>
                </Grid>
                <Typography variant="h2" style={{ textAlign: 'center' }}>
                  {userData.userInfo.login}
                </Typography>
                <Typography variant="h6" style={{ textAlign: 'center' }}>
                  {userInfoText ? userInfoText : 'No User Information given'}
                </Typography>
              </Container>
              <Tabs
                value={profileValue}
                onChange={(event, val) => { this.setState({ profileValue: val }) }}
                variant="fullWidth"
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    height: '4px',
                    borderRadius: '4px',
                  },
                }}
                textColor="primary"
              >
                <Tab label="User Information" style={{ color: profileValue === 0 ? '' : 'white' }} />
                <Tab label="Campaigns" style={{ color: profileValue === 1 ? '' : 'white' }} />
                <Tab label="KYC" style={{ color: profileValue === 2 ? '' : 'white' }} />
              </Tabs>
            </div>
            <div className={classes.profileContent}>
              {profileContent()}
            </div>
          </Paper>
        </div>
      </div>
    );
  }

  render() {
    return (
      <this.RenderProfilePage />
    );
  }
}

export default LandingPage;
