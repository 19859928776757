import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import NavBar from '../NavBar/NavBar';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    minWidth: '40%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  tosText: {
    marginBottom: theme.spacing(2),
  }
}));

export default function CreateProject(props) {
  const classes = useStyles();
  const { statisticsData, userData, history, onCampaignCreated, onLogout } = props;


  return (
    <main className={classes.layout}>
      <NavBar statisticsData={statisticsData} userData={userData} onLogout={onLogout} {...props} />
      <Paper className={classes.paper}>
          <Typography variant="h3" className={classes.tosText}>Terms of Service</Typography>
          <Typography variant="h4">Terms</Typography>
          <Typography variant="body1" className={classes.tosText}>By accessing or using this Service, provided from https://trezarfundme.com, you are agreeing to be bound
          by these Service Terms and Conditions of Use and agree that you are responsible for the agreement
          with any applicable local laws. If you disagree with any of these terms, you are prohibited from
          accessing this service or using our product. The materials contained in this Service are protected by
          copyright and trade mark law.</Typography>

          <Typography variant="h4">Termination or suspension</Typography>
          <Typography variant="body1" className={classes.tosText}>Furthermore we may terminate or suspend access to our Service immediately, without prior notice
          or liability, for any reason whatsoever, including without limitation if you breach the Terms.
          Reasons for terminating or suspending your access may include but are not limited to the following:
          • abuse of the platform;
          • advertising or sharing of inappropriate content;
          • excessive spam;
          If we decide to terminate or suspend the account of a user, we will inform him about it.
          All Funds will be locked which are collected until this point.</Typography>

          <Typography variant="h4">Revisions and Errata</Typography>
          <Typography variant="body1" className={classes.tosText}>The materials appearing on TrezarFundMe's Service may include technical, typographical, or
          photographic errors. TrezarFundMe will not promise that any of the materials in this Service
          are accurate, complete, or current. TrezarFundMe may change the materials contained on its
          Service at any time without notice. TrezarFundMe does not make any commitment to update
          the materials.</Typography>

          <Typography variant="h4">Links</Typography>
          <Typography variant="body1" className={classes.tosText}>TrezarFundMe has not reviewed all of the sites linked to its Service and is not responsible for
          the contents of any such linked site. The presence of any link does not imply endorsement by
            TrezarFundMe of the site. The use of any linked website is at the user’s own risk.</Typography>

          <Typography variant="h4">Your Privacy</Typography>
          <Typography variant="body1" className={classes.tosText}>We do not share any of the user's personal information and, in addition, only collect the data
          necessary and willingly provided by the user to use our service.
          Site Terms of Use Modifications
          TrezarFundMe may revise these Terms of Use for its Service at any time without prior notice.
          By using this Service, you are agreeing to be bound by the current version of these Terms and
          Conditions of Use.</Typography>

          <Typography variant="h4">Escrow</Typography>
          <Typography variant="body1">All funds get escrowed by TrezarFundMe and will released upon a successful delivery of the promised project.
          It may apply that the funds get frozen if the project doesn't deliver as promised and no payout will be
          done to the project creator.</Typography>
      </Paper>
    </main>
  );
}
