import React from 'react';

import {
  AppBar, CssBaseline, Drawer, Hidden, IconButton, Toolbar, Button,
  Container, Typography, Fab, MenuItem, ClickAwayListener, MenuList,
  Popper, Paper, Grow,
} from '@material-ui/core';

import {
  makeStyles, useTheme,
} from '@material-ui/core/styles';

import {
  Menu, AddCircleOutline, Search, AccountCircle, RemoveRedEye, ExitToApp, Person, Lock,
} from '@material-ui/icons';

import CONFIG from '../../config';
import TwitterIcon from '../../twitter-icon-white.svg';


const drawerWidth = 255;

const useStyles = makeStyles(theme => ({
  appBar: {
    marginLeft: drawerWidth,
    backgroundColor: '#1c1c1c',
    color: '#ffffff',
    display: 'relative',
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      backgroundColor: 'rgba(255,255,255,0)',
      color: '#000000',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.0), 0px 0px 0px 0px rgba(0,0,0,0.0), 0px 0px 0px 0px rgba(0,0,0,0.0)',
      display: 'flex',
    },
  },
  logo: {
    width: '230px',
    margin: '0 auto',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  fab: {
    margin: theme.spacing(1),
    width: drawerWidth - 20,
    textAlign: 'left',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  statsContainer: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  appbarTitle: {
    flexGrow: 1,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#1c1c1c',
    color: '#ffffff',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 1,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  smallPadding: {
    marginRight: '2px',
  },
  shareButton: {
    width: '24px',
    height: '24px',
    color: 'white!important',
  },
  footerContainer: {
    display: 'inline-flex',
    marginTop: '10px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerItem: {
    marginLeft: '12px',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }
}));

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }

    this.RenderNavBar = this.RenderNavBar.bind(this);
  }

  RenderNavBar() {
    const classes = useStyles();
    const theme = useTheme();
    const { history, statisticsData, userData, container, onLogout } = this.props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
      setOpen(prevOpen => !prevOpen);
    };

    // Open menu on click
    const handleClose = event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };
    const handleListKeyDown = (event) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    }
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
      prevOpen.current = open;
    }, [open]);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    }

    const drawer = (
      <div>
        <img src={CONFIG.MONOCHROME_LOGO} alt="Logo" className={classes.logo} onClick={() => history.push('/')} />
        <Fab variant="extended" aria-label="delete" className={classes.fab} onClick={() => history.push('/')}>
          <RemoveRedEye className={classes.extendedIcon} />
          Discover Campaigns
        </Fab>
        <Fab variant="extended" aria-label="delete" className={classes.fab} onClick={() => history.push('/create')}>
          <AddCircleOutline className={classes.extendedIcon} />
          Create a Campaign
        </Fab>
        <Fab variant="extended" aria-label="delete" className={classes.fab} onClick={() => history.push('/search')}>
          <Search className={classes.extendedIcon} />
          Search Campaigns
        </Fab>
        {userData.userInfo && userData.userInfo.is_admin && (
          <Fab variant="extended" aria-label="delete" className={classes.fab} onClick={() => history.push('/admin')}>
            <Lock className={classes.extendedIcon} />
            Admin Panel
          </Fab>
        )}
        <Container className={classes.statsContainer}>
          <Typography variant="h4">
            {statisticsData.totalCurrencyRaised}
          </Typography>
          <Typography variant="h5">
            Total {CONFIG.CURRENCY_NAME} Raised
          </Typography>
        </Container>
        <Container className={classes.statsContainer}>
          <Typography variant="h4">
            {statisticsData.totalProjects}
          </Typography>
          <Typography variant="h5">
            Total Campaigns
          </Typography>
        </Container>
        <Container className={classes.statsContainer}>
          <Typography variant="h4">
            {statisticsData.projectsFunded}
          </Typography>
          <Typography variant="h5">
            Campaigns Funded
          </Typography>
        </Container>
        <Container className={classes.footerContainer}>
          {/*<Typography variant="h6" className={classes.footerItem} onClick={() => history.push('/faq')}>
            FAQ
          </Typography>*/}
          <Typography variant="h6" className={classes.footerItem} onClick={() => history.push('/tos')}>
            ToS
          </Typography>
          <IconButton className={classes.footerItem}>
            <a href='https://twitter.com/TrezarCoin/'>
              <img src={TwitterIcon} className={classes.shareButton} />
            </a>
          </IconButton>
        </Container>
      </div>
    );

    const appBar = (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Hidden lgUp>
            <Typography variant="h6" className={classes.appbarTitle}>
              {CONFIG.APP_NAME}
            </Typography>
          </Hidden>
          <Hidden mdDown>
            <Typography variant="h6" className={classes.appbarTitle}>
              {/* Pushes Login and Sign Up to the right */}
            </Typography>
          </Hidden>
          {userData.token ? (
            <div>
              <IconButton
                onClick={handleToggle}
                color="inherit"
                ref={anchorRef}
              >
                <AccountCircle />
                <Typography variant="body2" className={classes.appbarTitle}>
                  {userData.userInfo.login}
                </Typography>
              </IconButton>
              <Popper open={open} anchorEl={anchorRef.current} keepMounted transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper id="menu-list-grow">
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList onKeyDown={handleListKeyDown}>
                          <MenuItem onClick={() => {
                            history.push('/profile');
                          }}>
                            <Person className={classes.smallPadding} />
                            Profile
                          </MenuItem>
                          <MenuItem onClick={() => {
                            onLogout();
                            history.push('/');
                          }}>
                            <ExitToApp className={classes.smallPadding} />
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          ) : (
              <div>
                <Button
                  onClick={() => history.push('/login')}
                  color="inherit">
                  Login
            </Button>
                <Button
                  onClick={() => history.push('/signup')}
                  color="inherit">
                  Sign Up
            </Button>
              </div>
            )}
        </Toolbar>
      </AppBar>
    );

    return (
      <nav className={classes.drawer}>
        <CssBaseline />
        {appBar}
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp>
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
        <div style={{ height: '30px' }} />
      </nav>
    );
  }


  render() {
    return (
      <this.RenderNavBar />
    );
  }
}

export default NavBar;
