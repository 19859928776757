import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import ProjectCard from '../ProjectCard/ProjectCard';
import NavBar from '../NavBar/NavBar';

import CONFIG from '../../config';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '80%',
    },
  },
  headerSpace: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(7),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
  categoryMenuSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignData: [],
      categoryValue: 0,
    };

    this.RenderLandingPage = this.RenderLandingPage.bind(this);
  }

  RenderLandingPage() {
    const classes = useStyles();

    const { history, campaignData, selectProjectCallback, statisticsData, userData, onLogout } = this.props;

    const { categoryValue } = this.state;

    const categoryMenu = (
      <div>
        <Hidden smDown>
          <Tabs
            value={categoryValue}
            onChange={(event, val) => { this.setState({ categoryValue: val }) }}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="All" />
            <Tab label="Productivity" />
            <Tab label="Developments" />
            <Tab label="Fashion" />
            <Tab label="Identity" />
          </Tabs>
        </Hidden>
        <Hidden smUp>
          <Tabs
            value={categoryValue}
            onChange={(event, val) => { this.setState({ categoryValue: val }) }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            style={{ maxWidth: '325px' }}
          >
            <Tab label="All" />
            <Tab label="Productivity" />
            <Tab label="Developments" />
            <Tab label="Fashion" />
            <Tab label="Identity" />
          </Tabs>
        </Hidden>
      </div>
    );

    const categories = ['All', 'Productivity', 'Developments', 'Fashion', 'Identity']
    const ProjectCards = campaignData
      .filter((campaign) => campaign.campaignCategory === categories[categoryValue] || categories[categoryValue] === 'All')
      .map((campaign, index) => {
        return (
          <Grid key={`campaign-${index}`} item onClick={(e) => {
            selectProjectCallback(index);
            history.push({
              pathname: '/viewproject',
              search: `?projectId=${campaign.campaignDbId}`,
            });
            e.stopPropagation();
          }}>
            <ProjectCard
              campaignTitle={campaign.campaignTitle}
              campaignSummary={campaign.campaignSummary}
              campaignCategory={campaign.campaignCategory}
              campaignFundingPercentage={campaign.campaignFundingPercentage}
              campaignGoal={campaign.campaignGoal}
              campaignRaised={campaign.campaignRaised}
              totalBackers={campaign.totalBackers}
              campaignCreator={campaign.campaignCreator}
              campaignCreationDate={campaign.campaignCreationDate}
              campaignPictureOrVideo={campaign.campaignPictureOrVideo}
              campaignStatus={campaign.campaignStatus}
              campaignDbId={campaign.campaignDbId}
              campaignType={campaign.campaignType}
            />
          </Grid>)
      });


    return (
      <div className={classes.root}>
        <CssBaseline />
        <NavBar statisticsData={statisticsData} userData={userData} onLogout={onLogout} {...this.props} />
        <main className={classes.content}>
          <div className={classes.headerSpace}>
            <Hidden smDown>
              <Typography variant="h2">{CONFIG.APP_NAME}</Typography>
              <Typography variant="h4">Supporting {CONFIG.CURRENCY_NAME} Community Projects</Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography variant="h4">Supporting {CONFIG.CURRENCY_NAME}  Projects</Typography>
            </Hidden>
          </div>
          <Divider variant="middle" />
          <div className={classes.categoryMenuSpacing}>
            {categoryMenu}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2}>
                {ProjectCards}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }

  render() {
    return (
      <this.RenderLandingPage />
    );
  }
}

export default LandingPage;
