import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import CONFIG from '../../config';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 325,
    width: 325,
    height: 525,
    maxHeight: 525,
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    '&:hover': {
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
      bottom: '4px',
      cursor: 'Pointer',
      '& h4': {
        textDecoration: 'underline',
      },
    }
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  progressContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  pivxHearth: {
    width: '26px',
    height: 'auto',
  },
  thickProgressbar: {
    height: '8px',
  },
  raisedDisplay: {
    float: 'left',
  },
  backerDisplay: {
    float: 'right',
    marginTop: '10px',
  },
  clearDiv: {
    clear: 'both',
  },
  campaignSummary: {
    minHeight: '80px',
  }
}));

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.RenderProjectCard = this.RenderProjectCard.bind(this);
  }

  RenderProjectCard(props) {
    const classes = useStyles();

    const {
      campaignTitle, campaignSummary, campaignCategory, campaignFundingPercentage,
      campaignGoal, campaignRaised, totalBackers, campaignCreator, campaignCreationDate, campaignPictureOrVideo,
      campaignStatus, campaignDbId, campaignType
    } = this.props;

    const formatTime = moment(campaignCreationDate).format('MMMM DD, YYYY');

    return (
      <Card
        className={classes.card}
        style={{ opacity: `${campaignStatus === 0 ? '0.35' : '1'}` }}
      >
        <CardHeader
          avatar={
            <Avatar aria-label="project" className={classes.avatar}>
              {campaignCreator ? campaignCreator.substring(0, 2) : '?'}
            </Avatar>
          }
          title={`${campaignStatus === 1 ? '' : 'No approved yet. Created by: '}${campaignCreator}${campaignStatus === 1 ? '' : ` - Campaign Id: ${campaignDbId}`}`}
          subheader={formatTime}
        />
        <CardMedia
          className={classes.media}
          image={campaignPictureOrVideo}
          title={campaignTitle}
        />
        <CardContent>
          <Typography variant="h5" color="primary">
            {campaignTitle}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" className={classes.campaignSummary}>
            {campaignSummary}
          </Typography>
          <div className={classes.progressContainer}>
            <div>
              <Typography variant="h6" color="primary" className={classes.raisedDisplay}>
                {campaignRaised} {campaignType === 'USD' ? '$' : CONFIG.CURRENCY_SYMBOL} raised
              </Typography>
              <Typography variant="body2" color="primary" className={classes.backerDisplay}>
                {totalBackers} Backers
              </Typography>
            </div>
            <div className={classes.clearDiv} />
            <LinearProgress color="primary" variant="determinate" value={Math.min(campaignFundingPercentage, 100)} className={classes.thickProgressbar} />
            <Typography variant="body2" color="primary">
              {campaignFundingPercentage}% of {campaignGoal} {campaignType === 'USD' ? '$' : CONFIG.CURRENCY_SYMBOL} Goal
            </Typography>
          </div>
          <Typography variant="h6" color="textSecondary">
            {campaignCategory}
          </Typography>
        </CardContent>
      </Card>
    )
  }

  render() {
    return (
      <this.RenderProjectCard />
    );
  }
}

export default LandingPage;
