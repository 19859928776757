import React from 'react';
import axios from 'axios';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import LinkIcon from '@material-ui/icons/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import InfoIcon from '@material-ui/icons/Info';
import FeedbackIcon from '@material-ui/icons/Feedback';
import UpdateIcon from '@material-ui/icons/Update';
import FacebookIcon from '../../facebook-icon.svg';
import TwitterIcon from '../../twitter-icon.svg';
import MUIRichTextEditor from 'mui-rte';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import NavBar from '../NavBar/NavBar';
import CONFIG from '../../config';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  campaignLogo: {
    width: '100%',
    margin: '0 auto',
    height: 'auto',
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
      marginTop: theme.spacing(1),
      padding: theme.spacing(0),
      backgroundColor: 'rgba(250, 250, 250, 1)',
      border: '0px solid white',
      boxShadow: '0px 0px white',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  textDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  avatarName: {
    marginLeft: '4px',
  },
  rootContainer: {
    flexGrow: 1,
  },
  campaignContainer: {
    display: 'flex',
  },
  fundingValueDisplay: {
    position: 'relative',
    top: '51px',
    left: '22px',
    fontSize: '18px',
  },
  fundingProgress: {
    width: '60px!important',
    margin: '0 auto',
    marginTop: theme.spacing(2),
  },
  goalAlign: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  commentRoot: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  createCommentField: {
    width: '100%',
    minWidth: '100%',
    marginLeft: '16px',
    borderRadius: '6px',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", "sans-serif"',
  },
  raisedDisplay: {
    float: 'left',
  },
  backerDisplay: {
    float: 'right',
    marginTop: '10px',
  },
  clearDiv: {
    clear: 'both',
  },
  buttonContainerSpacer: {
    marginTop: theme.spacing(3),
  },
  profileQuickViewContainer: {
    minHeight: '300px',
    maxWidth: '380px',
    backgroundImage: `linear-gradient(${theme.palette.primary.light}, #ffffff00)`,
    color: `${theme.palette.primary.contrastText}!important`,
  },
  profileQuickViewAvatar: {
    position: 'relative',
    width: '140px',
    height: '140px',
    border: '6px solid white',
    margin: '0 auto',
    marginTop: '-50px',
    top: '70px',
    backgroundColor: '#1c1c1c',
    fontSize: '2rem',
  },
  creatorNameInContainer: {
    paddingTop: '80px',
  },
  shareButton: {
    width: '24px',
    height: '24px',
  },
  gridDivider: {
    paddingLeft: '22px!important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '8px!important'
    },
  },
  thickProgressbar: {
    height: '8px',
  },
  categoryDisplay: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  postCommentButton: {
    marginLeft: '16px',
  },
  headerSpace: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(7),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(7),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
  avatarContainer: {
    paddingLeft: '0px',
  }
}));

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignLocalIndex: 0,
      detailViewMenuValue: 0,
      postCommentText: '',
      postUpdateText: '',
      campaignDbId: 0,
    };

    this.RenderLandingPage = this.RenderLandingPage.bind(this);
    this.postComment = this.postComment.bind(this);
    this.postUpdate = this.postUpdate.bind(this);
  }

  async postComment() {
    const { postCommentText, campaignDbId, campaignLocalIndex } = this.state;
    const { userData, onCommentAdded } = this.props;
    try {
      const postCommentResult = await axios.post(`${CONFIG.BACKEND_URL}/comments`, {
        commentText: postCommentText,
        campaignId: campaignDbId,
      }, {
        headers: {
          token: userData.token,
        },
      });
      const postCommentResultData = postCommentResult.data;
      postCommentResultData.user = {};
      postCommentResultData.user.login = userData.userInfo.login;
      console.log(postCommentResultData);
      onCommentAdded(campaignLocalIndex, postCommentResultData);
      this.setState({ postCommentText: '' });
    } catch (e) {
      console.log(e);
    }
  }

  async postUpdate() {
    const { postUpdateText, campaignDbId, campaignLocalIndex } = this.state;
    const { userData, onUpdateAdded } = this.props;
    try {
      const postUpdateResult = await axios.post(`${CONFIG.BACKEND_URL}/updates`, {
        updateText: postUpdateText,
        campaignId: campaignDbId,
      }, {
        headers: {
          token: userData.token,
        }
      });
      const postUpdateResultData = postUpdateResult.data;
      console.log(postUpdateResultData);
      onUpdateAdded(campaignLocalIndex, postUpdateResultData);
      this.setState({ postUpdateText: '' });
    } catch (e) {
      console.log(e);
    }
  }

  loadCampaignData() {
    const { selectProjectCallback, campaignData } = this.props;
    const params = new URLSearchParams(this.props.location.search);
    const campaignDbId = parseInt(params.get('projectId'));
    campaignData.forEach((campaign, index) => {
      if (campaign.campaignDbId === campaignDbId) {
        this.setState({
          campaignLocalIndex: index ,
          campaignDbId,
        });
        return;
      }
    });
    selectProjectCallback(this.state.campaignLocalIndex);
  }

  componentDidMount() {
    this.loadCampaignData();
  }


  RenderLandingPage() {
    const classes = useStyles();

    const { campaignLocalIndex, postCommentText, postUpdateText } = this.state;
    const { campaignData, statisticsData, userData, onLogout } = this.props;
    const [open, setOpen] = React.useState(false);
    const [copyAlertStatus, setCopyAlertStatus] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const copyAlertStatusHandleClose = () => {
      setCopyAlertStatus(false);
    };

    const copyAlertStatusHandleOpen = () => {
      setCopyAlertStatus(true);
    };

    if (campaignData.length === 0) {
      return (
        <CircularProgress />
      )
    }


    let projectComments = (
      <React.Fragment>
        <List className={classes.commentRoot}>
          {campaignData[campaignLocalIndex].campaignComments.map((comment, index) => {
            const formatTime = moment(comment.createdDate).format('MMMM DD, YYYY');
            return (
              <ListItem alignItems="flex-start" key={index}>
                <ListItemAvatar>
                  <Avatar alt={comment.user.login}>
                    {comment.user.login.substring(0, 2)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={formatTime}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {comment.user.login}
                      </Typography>
                      {` — ${comment.commentText}`}
                    </React.Fragment>
                  }
                />
              </ListItem>)
          })}
        </List>
        {userData.userInfo && (
          <React.Fragment>
            <TextareaAutosize
              className={classes.createCommentField}
              rows={12}
              value={postCommentText}
              onChange={(e) => this.setState({ postCommentText: e.target.value })}
              placeholder="Post a comment!"
            />
            <Button color="primary" variant="contained" className={classes.postCommentButton} onClick={() => this.postComment()}>
              Post Comment
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );


    const displayPostUpdates = userData.userInfo ? campaignData[campaignLocalIndex].campaignCreator === userData.userInfo.login : false;
    let updateSection = (
      <React.Fragment>
        <List>
          {campaignData[campaignLocalIndex].campaignUpdates.map((update, index) => {
            const formatTime = moment(update.createdDate).format('MMMM DD, YYYY');
            return (
              <ListItem key={index}>
                <ListItemText primary={`${formatTime}: ${update.updateText}`} />
              </ListItem>
            )
          })}
        </List>
        {displayPostUpdates && (
          <React.Fragment>
            <TextareaAutosize
              className={classes.createCommentField}
              value={postUpdateText}
              onChange={(e) => this.setState({ postUpdateText: e.target.value })}
              rows={12}
              placeholder="Please enter an update for your Project!"
            />
            <Button color="primary" variant="contained" className={classes.postCommentButton} onClick={() => this.postUpdate()}>
              Post Update
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );

    const formatTime = moment(campaignData[campaignLocalIndex].campaignCreationDate).format('MMMM DD, YYYY');
    return (
      <div className={classes.root}>
        <NavBar statisticsData={statisticsData} userData={userData} onLogout={onLogout} {...this.props} />
        <main className={classes.content}>
          <div className={classes.headerSpace}>
            <Hidden smDown>
              <Typography variant="h2">{CONFIG.APP_NAME}</Typography>
              <Typography variant="h4">Supporting {CONFIG.CURRENCY_NAME} Community Projects</Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography variant="h4">Supporting {CONFIG.CURRENCY_NAME} Projects</Typography>
            </Hidden>
          </div>
          <Divider variant="middle" />
          <Container>
            <Paper className={classes.paper}>
              <Grid container spacing={2} className={classes.rootContainer}>
                <Grid item xs={12}>
                  <Grid container justify="center" spacing={2}>
                    <Grid key={1} xs={12} md={7} item>
                      <img src={campaignData[campaignLocalIndex].campaignPictureOrVideo} alt="Campaign-Logo" className={classes.campaignLogo} />
                      <Divider variant="middle" className={classes.textDivider} />
                      <Tabs
                        value={this.state.detailViewMenuValue}
                        onChange={(event, val) => this.setState({ detailViewMenuValue: val })}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="icon label tabs detailviewmenu"
                      >
                        <Tab icon={<InfoIcon />} label="info" />
                        <Tab icon={<UpdateIcon />} label="updates" />
                        <Tab icon={<FeedbackIcon />} label="comments" />
                      </Tabs>
                      <div className={classes.textDivider} />
                      <div hidden={this.state.detailViewMenuValue !== 0} >
                        <MUIRichTextEditor
                          readOnly={true}
                          toolbar={false}
                          value={campaignData[campaignLocalIndex].campaignDescription}
                        />
                      </div>
                      <Typography variant="body2" role="tabpanel" component="div" hidden={this.state.detailViewMenuValue !== 1}>
                        {updateSection}
                      </Typography>
                      <Typography variant="body2" role="tabpanel" component="div" hidden={this.state.detailViewMenuValue !== 2}>
                        {projectComments}
                      </Typography>
                    </Grid>
                    <Grid key={2} xs={12} md={5} item className={classes.gridDivider} >
                      <Typography variant="h3">
                        {campaignData[campaignLocalIndex].campaignTitle}
                      </Typography>
                      <Typography variant="h6">
                        {campaignData[campaignLocalIndex].campaignSummary}
                      </Typography>
                      <Chip
                        color="primary"
                        className={classes.categoryDisplay}
                        label={campaignData[campaignLocalIndex].campaignCategory}
                        variant="outlined"
                      />
                      <div>
                        <CardHeader
                          avatar={
                            <Avatar aria-label="project" className={classes.avatar}>
                              {campaignData[campaignLocalIndex].campaignCreator.substring(0, 2)}
                            </Avatar>
                          }
                          title={`@${campaignData[campaignLocalIndex].campaignCreator}`}
                          subheader={formatTime}
                          className={classes.avatarContainer}
                        />
                      </div>
                      <div className={classes.progressContainer}>
                        <div>
                          <Typography variant="h6" color="primary" className={classes.raisedDisplay}>
                            {campaignData[campaignLocalIndex].campaignRaised} {campaignData[campaignLocalIndex].campaignType === 'USD' ? '$' : CONFIG.CURRENCY_SYMBOL} raised
                          </Typography>
                          <Typography variant="body2" color="primary" className={classes.backerDisplay}>
                            {campaignData[campaignLocalIndex].totalBackers} Backers
                        </Typography>
                        </div>
                        <div className={classes.clearDiv} />
                        <LinearProgress color="primary" variant="determinate" value={Math.min(campaignData[campaignLocalIndex].campaignFundingPercentage, 100)} className={classes.thickProgressbar} />
                        <Typography variant="body2" color="primary">
                          {campaignData[campaignLocalIndex].campaignFundingPercentage}% of {campaignData[campaignLocalIndex].campaignGoal} {campaignData[campaignLocalIndex].campaignType === 'USD' ? '$' : CONFIG.CURRENCY_SYMBOL} raised
                        </Typography>
                      </div>
                      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Back this Project</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            To support this campaign please send coins to the address. If you use tx-comment it will be displayed here. Thanks for you support!
                          </DialogContentText>
                          <TextField
                            margin="dense"
                            id="campaignAddress"
                            label="Campaign Address"
                            fullWidth
                            value={campaignData[campaignLocalIndex].campaignAddress}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} color="primary">
                            Ok
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <Grid container direction="row" alignItems="center" className={classes.buttonContainerSpacer}>
                        <Grid>
                          <Button color="primary" variant="contained" onClick={handleClickOpen}>
                            Back this Project
                        </Button>
                        </Grid>
                        <Grid>
                        {campaignData[campaignLocalIndex].campaignFacebook && (
                            <IconButton onClick={() => window.open(campaignData[campaignLocalIndex].campaignTwitter, '_blank')}>
                              <img
                                src={TwitterIcon}
                                alt="Twitter share"
                                className={classes.shareButton}
                              />
                            </IconButton>
                          )}
                          {campaignData[campaignLocalIndex].campaignFacebook && (
                            <IconButton onClick={() => window.open(campaignData[campaignLocalIndex].campaignFacebook, '_blank')}>
                              <img
                                src={FacebookIcon}
                                alt="Facebook share"
                                className={classes.shareButton}
                              />
                            </IconButton>
                          )}
                          <IconButton onClick={() => {
                                navigator.clipboard.writeText(`${CONFIG.WEBSITE_URL}/viewproject?projectId=${campaignData[campaignLocalIndex].campaignDbId}`);
                                copyAlertStatusHandleOpen();
                              }}>
                            <LinkIcon
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Avatar alt={campaignData[campaignLocalIndex].campaignCreator} className={classes.profileQuickViewAvatar}>
                        {campaignData[campaignLocalIndex].campaignCreator.substring(0, 2)}
                      </Avatar>
                      <Container className={classes.profileQuickViewContainer}>
                        <Typography variant="h5" className={classes.creatorNameInContainer}>
                          {campaignData[campaignLocalIndex].campaignCreator}
                        </Typography>
                        <Typography variant="body2">
                          {campaignData[campaignLocalIndex].campaignCreatorInfo}
                        </Typography>
                      </Container>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </main>
        <Snackbar open={copyAlertStatus} autoHideDuration={3000} onClose={copyAlertStatusHandleClose}>
          <Alert onClose={copyAlertStatusHandleClose} severity="success">
            Campaign Link copied to clipboard
          </Alert>
        </Snackbar>
      </div>
    );
  }

  render() {
    return (
      <this.RenderLandingPage />
    );
  }
}

export default LandingPage;
