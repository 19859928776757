import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import CONFIG from '../../config';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  colorLogo: {
    width: 'auto',
    height: '200px',
    marginBottom: theme.spacing(2),
  }
}));

export default function SignUp(props) {
  const classes = useStyles();
  const { history, onRegister } = props;

  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [repeatPassword, setRepeatPassword] = React.useState('');
  const [address, setAddress] = React.useState('');

  const [usernameError, setUsernameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = React.useState(false);
  const [addressError, setAddressError] = React.useState(false);

  const processRegister = async () => {
    /* Fetch errors */
    let error = false;
    if (username.length > 16 || username.length < 3) {
      setUsernameError(true);
      error = true;
    } else {
      setUsernameError(false);
    }

    if (email.length < 6 || email.length > 64 || email.indexOf('@') === -1) {
      setEmailError(true);
      error = true;
    } else {
      setEmailError(false);
    }

    if (password.length < 6 || password.length > 64) {
      setPasswordError(true);
      error = true;
    } else {
      setPasswordError(false);
    }

    if (password !== repeatPassword) {
      setRepeatPasswordError(true);
      error = true;
    } else {
      setRepeatPasswordError(false);
    }

    if (address.length !== 34 || !address.startsWith('T')) {
      setAddressError(true);
      error = true;
    } else {
      setAddressError(false);
    }

    if (error) {
      return;
    }

    try {
      const registerResult = await axios.post(`${CONFIG.BACKEND_URL}/register`, { login: username, password: password, address: address });
      const registerResultData = registerResult.data;
      console.log(registerResultData);
      if(registerResultData.success) {
        onRegister(registerResultData.data);
        history.push('/profile');
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={CONFIG.COLOR_LOGO} className={classes.colorLogo} alt={`${CONFIG.CURRENCY_NAME}-Logo`} />
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={usernameError}
                autoComplete="fusername"
                name="username"
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Username"
                autoFocus
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={emailError}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={passwordError}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={repeatPasswordError}
                variant="outlined"
                required
                fullWidth
                name="repeatpassword"
                label="Repeat Password"
                type="password"
                id="repeatpassword"
                autoComplete="repeat-password"
                onChange={(e) => setRepeatPassword(e.target.value)}
                value={repeatPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={addressError}
                variant="outlined"
                required
                fullWidth
                name={`${CONFIG.CURRENCY_NAME}address`}
                label={`${CONFIG.CURRENCY_NAME} Address`}
                id={`${CONFIG.CURRENCY_NAME}address`}
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => processRegister()}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
