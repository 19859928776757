import React from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';
import { makeStyles } from '@material-ui/core/styles';

import NavBar from '../NavBar/NavBar';
import CONFIG from '../../config';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  createProjectButton: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  createHeader: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  projectDescription: {
    width: '100%',
    minWidth: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  muiEditor: {
    minHeight: '300px',
    height: 'auto',
  }
}));

export default function CreateProject(props) {
  const classes = useStyles();
  const { statisticsData, userData, history, onCampaignCreated, onLogout } = props;

  const [title, setTitle] = React.useState('');
  const [goal, setGoal] = React.useState('');
  const [summary, setSummary] = React.useState('');
  const [pictureOrVideo, setPictureOrVideo] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [twitterLink, setTwitterLink] = React.useState('');
  const [facebookLink, setFacebookLink] = React.useState('');
  const [category, setCategory] = React.useState('Other');

  const [titleError, setTitleError] = React.useState(false);
  const [goalError, setGoalError] = React.useState(false);
  const [summaryError, setSummaryError] = React.useState(false);
  const [pictureOrVideoError, setPictureOrVideoError] = React.useState(false);
  const [descriptionError, setDescriptionError] = React.useState(false);
  const [twitterError, setTwitterError] = React.useState(false);
  const [facebookError, setFacebookError] = React.useState(false);


  const createCampaign = async () => {
    /* Error handling */
    let error = false;
    if (title === '' || title.length > 255) {
      setTitleError(true);
      error = true;
    } else {
      setTitleError(false);
    }

    if (goal === 0 || goal > 10000000 || goal === '') {
      setGoalError(true);
      error = true;
    } else {
      setGoalError(false);
    }

    if (summary === '' || summary.length < 12) {
      setSummaryError(true);
      error = true;
    } else {
      setSummaryError(false);
    }

    if (pictureOrVideo === '' || (!pictureOrVideo.startsWith('https://i.imgur.com/') && !pictureOrVideo.startsWith('https://youtube.com/watch?v='))) {
      setPictureOrVideoError(true);
      error = true;
    } else {
      setPictureOrVideoError(false);
    }

    if (description === '' || description.length < 255) {
      setDescriptionError(true);
      error = true;
    } else {
      setDescriptionError(false);
    }

    if (twitterLink !== '' && !twitterLink.startsWith('https://twitter.com/')) {
      setTwitterError(true);
      error = true;
    } else {
      setTwitterError(false);
    }

    if (facebookLink !== '' && !facebookLink.startsWith('https://facebook.com/')) {
      setFacebookError(true);
      error = true;
    } else {
      setFacebookError(false);
    }

    if (error) {
      return;
    }


    try {
      const createCampaignResult = await axios.post(`${CONFIG.BACKEND_URL}/campaigns`, {
        title,
        goal,
        subtitle: summary,
        picture_or_video: pictureOrVideo,
        description,
        twitter_link: twitterLink,
        facebook_link: facebookLink,
        currency: 'tzc',
        category,
      }, {
        headers: {
          token: userData.token,
        },
      });
      const createCampaignResultData = createCampaignResult.data;
      console.log(createCampaignResultData);
      if (createCampaignResultData.id) {
        onCampaignCreated(createCampaignResultData);
        history.push('/');
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <main className={classes.layout}>
      <NavBar statisticsData={statisticsData} userData={userData} onLogout={onLogout} {...props} />
      <Typography variant="h2" className={classes.createHeader}>
        Create a Project
      </Typography>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              error={titleError}
              required
              id="title"
              name="title"
              label="Title"
              fullWidth
              autoComplete="title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              error={goalError}
              required
              id="goal"
              name="goal"
              label={`Goal in ${CONFIG.CURRENCY}`}
              fullWidth
              autoComplete="goal"
              value={goal}
              onChange={(e) => {
                if (e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) {
                  setGoal(e.target.value)
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={summaryError}
              required
              id="summary"
              name="summary"
              label="Summary"
              fullWidth
              autoComplete="summary"
              onChange={(e) => setSummary(e.target.value)}
              value={summary}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={pictureOrVideoError}
              required
              id="pictureOrVideoLink"
              name="pictureOrVideoLink"
              label="Imgur or Youtube direct link"
              fullWidth
              autoComplete="pictureOrVideoLink"
              onChange={(e) => setPictureOrVideo(e.target.value)}
              value={pictureOrVideo}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={facebookError}
              id="facebookLink"
              name="facebookLink"
              label="Optional: Facebook link"
              fullWidth
              autoComplete="facebookLink"
              onChange={(e) => setFacebookLink(e.target.value)}
              value={facebookLink}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={twitterError}
              id="twitterLink"
              name="twitterLink"
              label="Optional: Twitter link"
              fullWidth
              autoComplete="twitterLink"
              onChange={(e) => setTwitterLink(e.target.value)}
              value={twitterLink}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="category-helper">Category</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                inputProps={{
                  name: 'category',
                  id: 'category-helper',
                }}
              >
                <MenuItem value={'Other'}>Other</MenuItem>
                <MenuItem value={'Productivity'}>Productivity</MenuItem>
                <MenuItem value={'Developments'}>Developments</MenuItem>
                <MenuItem value={'Fashion'}>Fashion</MenuItem>
                <MenuItem value={'Identity'}>Identity</MenuItem>
              </Select>
              <FormHelperText>Choose the category</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.muiEditor}>
            <MUIRichTextEditor
              label="Please enter a detailed description about your Project!"
              onChange={(state) => setDescription(JSON.stringify(convertToRaw(state.getCurrentContent())))}
              maxLength={10000}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={createCampaign}
          className={classes.createProjectButton}
        >
          Create Campaign
          </Button>
      </Paper>
    </main>
  );
}
