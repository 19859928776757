import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import {
  Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Divider, Typography
} from '@material-ui/core';

import {
  Visibility, VisibilityOff, Delete,
} from '@material-ui/icons';

import NavBar from '../NavBar/NavBar';
import CONFIG from '../../config';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '80%',
    },
  },
  headerSpace: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(2),
  },
}));

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.RenderAdminView = this.RenderAdminView.bind(this);
  }



  RenderAdminView() {
    const classes = useStyles();
    const { campaignData, statisticsData, userData, onLogout, fetchCampaignData } = this.props;

    const approveCampaign = async (campaignId) => {
      try {
        const approveResult = await axios.post(`${CONFIG.BACKEND_URL}/campaigns/approve`, {
          campaignId,
        },
        {
          headers: {
            token: userData.token,
          },
        });
        const result = approveResult.data;
        console.log(result);
      } catch (e) {
        console.error(e);
      }
      fetchCampaignData();
    };

    const revokeCampaign = async (campaignId) => {
      try {
        const approveResult = await axios.post(`${CONFIG.BACKEND_URL}/campaigns/revoke`, {
          campaignId,
        },
        {
          headers: {
            token: userData.token,
          },
        });
        const result = approveResult.data;
        console.log(result);
      } catch (e) {
        console.error(e);
      }
      fetchCampaignData();
    };

    const deleteCampaign = async (campaignId) => {
      try {
        const approveResult = await axios.post(`${CONFIG.BACKEND_URL}/campaigns/delete`, {
          campaignId,
        },
        {
          headers: {
            token: userData.token,
          },
        });
        const result = approveResult.data;
        console.log(result);
      } catch (e) {
        console.error(e);
      }
      fetchCampaignData();
    };

    const campaignTable = campaignData.sort((a,b) => a.campaignDbId - b.campaignDbId).map((campaign) => {
      return (
        <TableRow key={`row-${campaign.campaignDbId}`}>
          <TableCell>
            {campaign.campaignDbId}
          </TableCell>
          <TableCell>
            {campaign.campaignTitle}
          </TableCell>
          <TableCell>
            {campaign.campaignRaised} / {campaign.campaignGoal}
          </TableCell>
          <TableCell>
            {campaign.campaignStatus === 0 ? 'No' : 'Yes'}
          </TableCell>
          <TableCell>
            {campaign.campaignStatus === 0 ?
              (
                <Tooltip title="Approve Campaign" aria-label="Approve Campaign">
                  <IconButton onClick={() => approveCampaign(campaign.campaignDbId)}>
                    <Visibility />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Revoke Campaign" aria-label="Revoke Campaign">
                  <IconButton onClick={() => revokeCampaign(campaign.campaignDbId)}>
                    <VisibilityOff />
                  </IconButton>
                </Tooltip>
              )}
            <Tooltip title="Delete Campaign" aria-label="Delete Campaign">
              <IconButton onClick={() => deleteCampaign(campaign.campaignDbId)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow >
      );
  })

  //const formatTime = moment(campaignData[campaignId].campaignCreationDate).format('MMMM DD, YYYY');
  return(
      <div className = { classes.root } >
      <NavBar statisticsData={statisticsData} userData={userData} onLogout={onLogout} {...this.props} />
      <main className={classes.content}>
        <Typography variant="h4">
          Admin Panel
        </Typography>
        <Divider />
        <div className={classes.headerSpace}>
          <Paper elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Id
                    </TableCell>
                  <TableCell>
                    Title
                    </TableCell>
                  <TableCell>
                    Goal
                    </TableCell>
                  <TableCell>
                    Approved
                    </TableCell>
                  <TableCell>
                    Actions
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaignTable}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </main>
      </div>
    );
  }

render() {
  return (
    <this.RenderAdminView />
  );
}
}

export default LandingPage;
