import React from 'react';
import axios from 'axios';
import './App.css';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import LandingPage from './components/LandingPage/LandingPage';
import SignUp from './components/SignUp/SignUp';
import Login from './components/Login/Login';
import Profile from './components/Profile/Profile';
import CreateProject from './components/CreateProject/CreateProject';
import ProjectDetailView from './components/ProjectDetailView/ProjectDetailView';
import AdminPanelView from './components/AdminPanelView/AdminPanelView';
import ToS from './components/ToS/ToS';
import SearchCampaign from './components/SearchCampaign/SearchCampaign';

import { MuiThemeProvider } from '@material-ui/core/styles';
import customTheme from './theme/muiTheme';

import NavBar from './components/NavBar/NavBar';
import CONFIG from './config';
import { CircularProgress, Typography } from '@material-ui/core';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignData: [],
      userData: {},
      selectedProjectIndex: 0,
      statisticsData: {},
    }

    this.fetchCampaignData = this.fetchCampaignData.bind(this);
    this.processStatisticsData = this.processStatisticsData.bind(this);
    this.selectProjectCallback = this.selectProjectCallback.bind(this);
    this.onLoginCallback = this.onLoginCallback.bind(this);
    this.onRegisterCallback = this.onRegisterCallback.bind(this);
    this.onCampaignCreated = this.onCampaignCreated.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onCommentAdded = this.onCommentAdded.bind(this);
    this.onUpdateAdded = this.onUpdateAdded.bind(this);
  }

  async componentWillMount() {
    const session = localStorage.getItem('user');
    if (session !== null) {
      this.setState({ userData: JSON.parse(session) }, () => {
        this.fetchCampaignData();
      });
    } else {
      this.fetchCampaignData();
    }
  }

  async fetchCampaignData() {
    const { userData } = this.state;
    console.log(userData);
    let tempCampaignData = [];

    let campaignResultData;
    try {
      const campaignResult = await axios.get(`${CONFIG.BACKEND_URL}/campaigns`, {
        headers: {
          token: userData.token,
        },
      });
      campaignResultData = campaignResult.data.reverse();
      // console.log(campaignResultData);
    } catch (e) {
      console.log(e);
      return;
    }

    for (let i = 0; i < campaignResultData.length; i += 1) {
      const campaign = campaignResultData[i];
      tempCampaignData.push({
        campaignTitle: campaign.title,
        campaignSummary: campaign.subtitle,
        campaignCategory: campaign.category,
        campaignDescription: campaign.description,
        campaignPictureOrVideo: campaign.picture_or_video,
        campaignAddress: campaign.address,
        campaignGoal: Number.parseInt(campaign.goal),
        campaignRaised: Number.parseInt(campaign.totalRaised),
        campaignFundingPercentage: Math.floor(Number.parseInt(campaign.totalRaised, 10) / Number.parseInt(campaign.goal, 10) * 100),
        totalBackers: campaign.totalBackers,
        campaignCreator: campaign.user.login,
        campaignCreatorInfo: campaign.user.user_info,
        campaignCreatorAvatar: campaign.user.avatar,
        campaignCreationDate: campaign.created_date,
        campaignEndDate: campaign.end_date,
        campaignTwitterLink: campaign.twitter_link,
        campaignFacebookLink: campaign.facebook_link,
        campaignComments: campaign.comments,
        campaignUpdates: campaign.updates,
        campaignDbId: campaign.id,
        campaignStatus: campaign.status,
        campaignType: campaign.campaigntype,
      })
    }

    this.setState({ campaignData: tempCampaignData });
    this.processStatisticsData(tempCampaignData);
  }

  processStatisticsData(campaignData) {
    let tempStatisticsData = {};

    tempStatisticsData.totalCurrencyRaised = 0;
    tempStatisticsData.totalProjects = 0;
    tempStatisticsData.projectsFunded = 0;

    for (let i = 0; i < campaignData.length; i += 1) {
      const totalRaisedNum = Number.parseInt(campaignData[i].campaignRaised, 10);
      const goalNum = Number.parseInt(campaignData[i].campaignGoal, 10);
      tempStatisticsData.totalCurrencyRaised += totalRaisedNum;
      tempStatisticsData.totalProjects += 1;
      if (totalRaisedNum >= goalNum) {
        tempStatisticsData.projectsFunded += 1;
      }
    }

    this.setState({ statisticsData: tempStatisticsData });
  }

  selectProjectCallback(selectedCampaignLocalIndex) {
    this.setState({ selectedProjectIndex: selectedCampaignLocalIndex });
  }

  onLoginCallback(userObject) {
    this.setState({
      userData: userObject,
    });
    localStorage.setItem('user', JSON.stringify(userObject));
  }

  onRegisterCallback(userObject) {
    this.setState({
      userData: userObject,
    });
  }

  onCampaignCreated(campaignData) {
    this.fetchCampaignData();
  }

  onCommentAdded(campaignLocalIndex, commentObject) {
    const { campaignData } = this.state;
    const tempCampaignData = campaignData;
    tempCampaignData[campaignLocalIndex].campaignComments.push(commentObject);
  }

  onUpdateAdded(campaignLocalIndex, updateObject) {
    const { campaignData } = this.state;
    const tempCampaignData = campaignData;
    tempCampaignData[campaignLocalIndex].campaignUpdates.splice(0, 0, updateObject);
  }

  onLogout() {
    this.setState({
      userData: {},
    });
    localStorage.removeItem('user');
  }

  render() {
    const { campaignData, userData, statisticsData } = this.state;
    return (
      <MuiThemeProvider theme={customTheme}>
        <Router>
          <CssBaseline />
          <Route
            exact
            path="/"
            render={props => (
              <div>
                <LandingPage
                  {...props}
                  campaignData={campaignData}
                  statisticsData={statisticsData}
                  selectProjectCallback={this.selectProjectCallback}
                  userData={userData}
                  onLogout={this.onLogout}
                />
              </div>)}
          />
          <Route
            exact
            path="/search"
            render={props => (
              <div>
                <SearchCampaign
                  {...props}
                  campaignData={campaignData}
                  statisticsData={statisticsData}
                  selectProjectCallback={this.selectProjectCallback}
                  userData={userData}
                  onLogout={this.onLogout}
                />
              </div>)}
          />
          <Route
            exact
            path="/signup"
            render={props => (
              <div>
                <NavBar
                  statisticsData={statisticsData}
                  userData={userData}
                  onLogout={this.onLogout}
                  {...props}
                />
                <SignUp
                  onRegister={this.onRegisterCallback}
                  {...props}
                />
              </div>)}
          />
          <Route
            exact
            path="/login"
            render={props => (
              <div>
                <NavBar
                  statisticsData={statisticsData}
                  userData={userData}
                  onLogout={this.onLogout}
                  {...props}
                />
                <Login
                  onLogin={this.onLoginCallback}
                  {...props}
                />
              </div>)}
          />
          <Route
            exact
            path="/profile"
            render={props => (
              <div>
                {userData.token ? (
                  <Profile
                    campaignData={campaignData}
                    statisticsData={statisticsData}
                    userData={userData}
                    onLogout={this.onLogout}
                    selectProjectCallback={this.selectProjectCallback}
                    {...props}
                  />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                  )}
              </div>)}
          />
          <Route
            exact
            path="/create"
            render={props => (
              <div>
                {userData.token ?
                (
                  <CreateProject
                    {...props}
                    statisticsData={statisticsData}
                    userData={userData}
                    onLogout={this.onLogout}
                    onCampaignCreated={this.onCampaignCreated}
                  />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} userData={userData} />
                )}
              </div>)}
          />
          <Route
            exact
            path="/viewproject"
            render={props => (
              <div>
                {campaignData.length > 0 ? (
                <ProjectDetailView
                  {...props}
                  statisticsData={statisticsData}
                  onLogout={this.onLogout}
                  campaignData={campaignData}
                  selectProjectCallback={this.selectProjectCallback}
                  userData={userData}
                  onUpdateAdded={this.onUpdateAdded}
                  onCommentAdded={this.onCommentAdded}
                />
                ):(
                  <CircularProgress />
                )}
              </div>)}
          />
          <Route
            exact
            path="/admin"
            render={props => (
              <div>
                {userData.userInfo && userData.userInfo.is_admin ?
                (
                  <AdminPanelView
                    {...props}
                    statisticsData={statisticsData}
                    onLogout={this.onLogout}
                    campaignData={campaignData}
                    userData={userData}
                    fetchCampaignData={this.fetchCampaignData}
                  />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} userData={userData} />
                )}
              </div>)}
          />
          <Route
            exact
            path="/tos"
            render={props => (
              <div>
                <ToS
                  {...props}
                  campaignData={campaignData}
                  statisticsData={statisticsData}
                  selectProjectCallback={this.selectProjectCallback}
                  userData={userData}
                  onLogout={this.onLogout}
                />
              </div>)}
          />
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
